import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import RuledBreak from "../../components/RuledBreak"
import { ButtonTrace } from "../../components/Button"

const Education = ({ data }) => {
  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={headerImg}
      alt={"Cali Reeves Fan Like a Pro"}
      height={400}
      mobile={300}
    >
      <SEO title="Education" keywords={["Education", "Learning"]} />
      {/* ----------------------------------------------------------------------- */}
      <div className="row my-5">
        <div className="col-md-12 py-3">
          <RuledBreak>Education</RuledBreak>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-md-4 py-3">
          <ButtonTrace to="/education/memberships" btn={3} className="mt-2">
            MEMBERSHIPS
          </ButtonTrace>
        </div>
        <div className="col-md-4 py-3">
          <ButtonTrace to="/education/trainings" btn={3} className="mt-2">
            TRAININGS
          </ButtonTrace>
        </div>
        <div className="col-md-4 py-3">
          <ButtonTrace to="/education/videos" btn={3} className="mt-2">
            VIDEOS
          </ButtonTrace>
        </div>
      </div>
      {/* ----------------------------------------------------------------------- */}
    </Layout>
  )
}

export const query = graphql`
  query educationQuery {
    header: allFile(filter: { name: { eq: "home_header_1200_791" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default Education
